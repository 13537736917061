<template>
  <section class="assets-details">
    <div class="top-bar bg-white">
      <div style="display: flex; margin-top: 20px">
        <div style="margin-right: 10px">
          <span style="padding: 0 10px">小区名称:</span>
          <el-input
            size="small"
            style="width: 180px"
            v-model="formSearch.communityName"
            placeholder="请输入小区名称"
          />
        </div>
        <div style="margin-right: 10px">
          <span style="padding: 0 10px">房间号:</span>
          <el-input
            size="small"
            style="width: 200px"
            v-model="formSearch.apartmentName"
            placeholder="请输入房间号"
          />
        </div>
        <!-- 2023.08.11 张晓瑜新增使用部门 -->
        <!-- <div style="margin-right: 10px">
          <span style="padding: 0 10px">使用部门:</span>
          <el-input
            size="small"
            style="width: 200px"
            v-model="formSearch.apartmentName"
            placeholder="请输入使用部门"
          />
        </div> -->
        <!-- <div style="margin-right: 10px">
        <span style="padding: 0 10px">资产类别:</span>
        <el-select
          size="small"
          v-model="formSearch.type"
          style="width: 200px"
          placeholder="请选择资产类别"
        >
          <el-option label="全部" :value="0" />
          <el-option
            v-for="(item, index) in assetsTypeList"
            :key="index"
            :label="item.codeName"
            :value="item.code"
          />
        </el-select>
      </div> -->
      <div style="margin-right: 10px">
          <span style="padding: 0 10px">资产状态:</span>
          <el-select
            size="small"
            v-model="formSearch.assetsState"
            style="width: 200px"
            placeholder="请选择资产状态"
          >
            <el-option label="全部" :value="0" />
            <el-option
              v-for="(item, index) in assetsStateList"
              :key="index"
              :label="item.codeName"
              :value="item.code"
            />
          </el-select>
        </div>
        <div style="margin-right: 10px">
          <span style="padding: 0 10px">购入时间:</span>
          <!-- <el-date-picker
          v-model="formSearch.time"
          type="datetime"
          placeholder="选择日期时间"
          size="small"
          value-format="yyyy-MM-dd HH:mm:ss"
        /> -->
          <el-date-picker
            v-model="purchaseAndEndDate"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            size="small"
            style="width: 70%"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </div>
      </div>

      <div
        style="
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin-top: 10px;
        "
      >
        <el-button type="primary" size="small" @click="handleSearch"
          >查询</el-button
        >
        <el-button size="small" @click="handleReset">重置</el-button>
        <el-button type="primary" size="small" @click="handleImport"
          >导入</el-button
        >
        <el-button type="primary" size="small" @click="getListExport"
          >导出</el-button
        >
        <el-button type="primary" size="small" @click="exportAllTag"
          >导出标签</el-button
        >
        <!-- 2023.08.21 张晓瑜新增批量删除按钮 -->
        <el-button type="primary" size="small" @click="batchDelete"
          >批量删除</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-arrow-left"
          @click="goBack"
          >返回</el-button
        >
      </div>
    </div>
    <div style="padding: 25px; background-color: #ffffff; border-radius: 10px">
      <r-e-table
        class="bg-white"
        ref="rentTableRef"
        :dataRequest="getAssetsList"
        :columns="tableColumn"
        :query="formSearch"
        :height="700"
        notAuto
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <el-empty />
        </template>
        <el-table-column slot="toolbar" label="操作" width="200">
          <template slot-scope="{ row }">
            <div class="table-tools">
              <span class="table-btn" @click="look(row)">查看图片</span>
              <span class="table-btn" @click="print(row)">导出标签</span>
              <span class="table-btn" @click="openInventory(row)"
                >盘点记录</span
              >
              <span
                class="table-btn"
                v-if="row.stateCode === 700102"
                @click="recycle(row)"
                >回收</span
              >
              <span
                class="table-btn"
                v-if="row.stateCode === 700101"
                @click="allocation(row)"
                >分配</span
              >
              <span
                class="table-btn"
                v-if="row.verifyTime === null"
                @click="detailsDelete(row)"
                >删除</span
              >
            </div>
          </template>
        </el-table-column>
      </r-e-table>
    </div>

    <r-e-dialog
      title="查看图片"
      class="layer-contract-detail"
      :visible.sync="dialogVisible"
      top="10vh"
    >
      <div class="flex" style="height: 500px; overflow: scroll">
        <div v-for="(item, index) in imgList" :key="index">
          <div
            class="flex justify-center"
            style="
              background: #f5f7fa;
              width: 225px;
              height: 150px;
              border: 1px solid #f1f1f3;
              border-radius: 5px;
              margin: 0 10px;
            "
          >
            <el-image
              :src="createFullImageUrl(item)"
              class="flex align-center"
              :preview-src-list="[createFullImageUrl(item)]"
            >
              <div slot="error" style="font-size: 30px">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">加载中...</div>
            </el-image>
          </div>
        </div>
      </div>
    </r-e-dialog>

    <layer-assets-details-inventory ref="layerAssetsDetailsInventory" />

    <layer-allocation-room
      ref="layerAllocationRoom"
      @handleSearch="handleSearch"
    />

    <layer-assets-details-import
      ref="layerAssetsDetailsImport"
      @click-cancel="handleSearch"
    />

    <layer-label-preview ref="layerLabelPreview" @click-cancel="handleSearch" />
  </section>
</template>

<script>
import { assetsDetailsTableColumn } from '@/views/property-management/assets-management/data'
import {
  getAssetsList,
  getListExport,
  recycle,
  detailsDelete,
  batchDeleteApi
} from '@/api/assets'
import { downloadByData } from '@/utils/hooks/download'
import { createFullImageUrl } from '@/components/Upload/index'
import layerAssetsDetailsInventory from './components/layer-assets-details-inventory'
import { MessageInfo, MessageSuccess } from '@custom/message'
import jcPrinterSdk from '@/assets/js/jcPrinterSdk_api_third'
import { combinationPrintData, fangjianming } from './js/label-template'

export default {
  name: 'assets-details',
  components: {
    layerAssetsDetailsInventory,
    layerAllocationRoom: () => import('./components/layer-allocation-room'),
    layerAssetsDetailsImport: () =>
      import('./components/layer-assets-details-import'),
    layerLabelPreview: () => import('./components/layer-label-preview')
  },
  data () {
    return {
      tableColumn: assetsDetailsTableColumn(this),
      formSearch: {
        assetsState: 0,
        type: 0,
        time: null,
        assetsUuid: null,
        apartmentName: null, // 使用部门
        startDate: '', // 开始时间
        endDate: '' ,// 结束时间
        communityName: null
      },
      assetsStateList: [],
      assetsTypeList: [],
      loadingOptions: {
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      },
      dialogVisible: false,
      imgList: [],
      isPrinterOnline: true,
      labelSelectArray: [],
      loading: null,
      purchaseAndEndDate: [], // 购入时间
      listArray: []
    }
  },
  async mounted () {
    const that = this
    jcPrinterSdk.jcPrinterInit()
    const { assetsUuid } = this.$route.query
    this.formSearch.assetsUuid = assetsUuid
    // console.log(assetsUuid);
    this.handleSearch()
    this.assetsStateList = await this.$store.dispatch(
      'app/getDictionaryByCodeActions',
      700100
    )
    this.assetsTypeList = await this.$store.dispatch(
      'app/getDictionaryByCodeActions',
      200000
    )
  },
  methods: {
    getAssetsList (params) {
      return getAssetsList(params)
    },
    // 查询
    handleSearch () {
      this.$refs['rentTableRef'].getTableData(true)
    },
    //  重置
    handleReset () {
      this.formSearch.assetsState = 0
      this.formSearch.apartmentName = ''
      this.formSearch.communityName = ''
      this.purchaseAndEndDate = []
      Promise.resolve(this.formSearch).then(() => this.handleSearch())
    },
    // 返回
    goBack () {
      this.$router.back()
    },
    // 导出标签
    exportAllTag () {
      const that = this
      /*const {assetsState, assetsUuid} = that.formSearch;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            getTagListPrint({assetsState, assetsUuid}).then(res => {
                const {msg} = res;
                const blob = dataURItoBlob(msg);
                downloadByData({data: blob, filename: "资产标签.zip"});
            }).finally(() => loading.close());*/
      const { labelSelectArray } = that
      if (labelSelectArray.length === 0) {
        MessageInfo('请先选择打印标签')
        return
      }
      that
        .$confirm(
          `您已勾选${labelSelectArray.length}个资产，确认打印标签？`,
          '注意',
          {
            type: 'warning'
          }
        )
        .then(() => {
          const loadingOptions = that.loadingOptions
          that.loading = that.$loading({ ...loadingOptions })
          jcPrinterSdk.batchPrintJob(that, labelSelectArray, false)
        })
        .catch(() => {})
    },
    // 导出
    getListExport () {
      const that = this
      const {
        assetsName,
        assetsState,
        companyStockUuid,
        assetsUuid,
        apartmentName,
        startDate,
        endDate,
        communityName
      } = that.formSearch
      const loadingOptions = that.loadingOptions
      const loading = that.$loading({ ...loadingOptions })
      getListExport({
        assetsName,
        assetsState,
        companyStockUuid,
        assetsUuid,
        apartmentName,
        startDate,
        endDate,
        communityName
      })
        .then(res => {
          downloadByData({ data: res, filename: '资产列表.xlsx' })
        })
        .finally(() => loading.close())
    },
    // 导出标签
    print (data) {
      /*const {uuid} = data;
            const that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            print(uuid).then(res => {
                if (res) {
                    const binaryData = [];
                    binaryData.push(res);
                    let pdfUrl = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
                    let tempwindow = window.open("", '_blank');
                    tempwindow.location = pdfUrl;
                }
            }).finally(() => loading.close());*/
      this.$refs['layerLabelPreview'].openDialog(data)
    },

    createFullImageUrl (uuid) {
      return createFullImageUrl(uuid)
    },
    // 查看图片
    look (data) {
      const { photo } = data
      this.imgList = photo ? photo.split(';').filter(item => item !== '') : []
      this.dialogVisible = true
    },
    // 盘点记录
    openInventory (data) {
      this.$refs['layerAssetsDetailsInventory'].openDialog(data)
    },
    // 回收
    recycle (data) {
      const { uuid } = data
      let that = this
      this.$confirm('此操作将回收当前资产, 是否继续?', '注意', {
        type: 'warning'
      })
        .then(() => {
          const loadingOptions = that.loadingOptions
          const loading = that.$loading({ ...loadingOptions })
          recycle({ assetDetailUuid: uuid })
            .then(res => {
              MessageSuccess('回收成功')
              that.handleSearch()
            })
            .finally(() => loading.close())
        })
        .catch(() => {})
    },
    // 分配
    allocation (data) {
      this.$refs['layerAllocationRoom'].openDialog(data)
    },
    // 删除
    detailsDelete (data) {
      const { uuid } = data
      let that = this
      this.$confirm('此操作将删除当前资产, 是否继续?', '注意', {
        type: 'warning'
      })
        .then(() => {
          const loadingOptions = that.loadingOptions
          const loading = that.$loading({ ...loadingOptions })
          detailsDelete({ assetDetailUuid: uuid })
            .then(res => {
              MessageSuccess('删除成功')
              that.handleSearch()
            })
            .finally(() => loading.close())
        })
        .catch(() => {})
    },
    // 导入
    handleImport () {
      this.$refs['layerAssetsDetailsImport'].openDialog(this.formSearch)
    },
    // 多选框选中数据
    handleSelectionChange (e) {
      // console.log('e---->',e);
      const labelSelectArray = []
      // const listArray = e.map(item=>item.uuid)
      const listArray = e.map(item => item.uuid)
      // console.log('listArray',listArray);
      e.map(({ uuid, name, assetType, asstesName }) => {
        const { InitDrawingBoardParam, elements } = JSON.parse(
          JSON.stringify(combinationPrintData)
        )
        elements[0].json.value = `https://rental.neoyon.com/h5/assets/index.html?uuid=${uuid}_;`
        // elements[1].json.value = "南宁富航公司\n" + assetType;
        elements[1].json.value = '南宁富航公司\n' + asstesName
        if (name) {
          const fangjianming1 = JSON.parse(JSON.stringify(fangjianming))
          elements.push(fangjianming1)
          elements[2].json.value = name
        }
        labelSelectArray.push({ InitDrawingBoardParam, elements })
        return { uuid, name, assetType }
      })
      this.labelSelectArray = labelSelectArray
      this.listArray = listArray
    },
    // 新增批量删除
    batchDelete () {
      console.log('res--this.listArray', this.listArray)
      this.$confirm('您已勾选选中内容并将其删除, 请确认?', '批量删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await batchDeleteApi({ uuids: this.listArray })
          console.log('res--', res)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.handleSearch()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  },
  watch: {
    // 购入时间
    purchaseAndEndDate (value) {
      if (value && value.length !== 0) {
        let [startDate, endDate] = value
        this.formSearch.startDate = startDate
        this.formSearch.endDate = endDate
      } else {
        this.formSearch.startDate = null
        this.formSearch.endDate = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.assets-details {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(10px);
    padding: VH(15px) VW(15px);
    justify-content: center;
    flex-wrap: wrap;
    .el-radio-button.is-active {
      box-shadow: 0 0 10px #ddd inset;
    }
  }

  /deep/ .r-e-table {
    .el-table .cell {
      //white-space: nowrap;
      text-align: center;
    }
  }

  .title {
    height: VH(50px);
    line-height: VH(50px);
    color: #666;
    padding-left: VW(10px);
    position: relative;

    &::before {
      width: 5px;
      height: 40%;
      background-color: #5c84fb;
      content: '';
      position: absolute;
      left: 0;
      top: 30%;
    }
  }
}
</style>
